import magnificPopup from "magnific-popup/dist/jquery.magnific-popup.min.js";

$(document).ready(function() {
	$('.popup-gallery, .product-slider-big, .news_gallery, .recipe-gallery-slider').each(function() {
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1]
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			},
			callbacks: {
				  lazyLoad: function(item) {
					preload: [1,3]
				  }
			}
		});
	})

	if($('.gallery-photo').length) {
        $('.gallery-photo').each(function() {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0,1]
                },
                callbacks: {
					lazyLoad: function(item) {
				    	preload: [1,3]
				  	}
                }
            });
        });
    }
});
