import mCustomScrollbar from "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js";

$(document).ready(function () {
    $(".viewed-product-wrap .tabs").mCustomScrollbar({
	    axis:"x",
        scrollbarPosition: 'inside',
        scrollInertia: 150,
        scrollEasing:"linear",
            advanced:{
              autoExpandHorizontalScroll:true,
              updateOnBrowserResize:true,
              updateOnContentResize:true,
          },
	});

    $(".tabs-product-wrap .tabs").mCustomScrollbar({
	    axis:"x",
        scrollbarPosition: 'inside',
        scrollInertia: 150,
        scrollEasing:"linear",
        advanced:{
          autoExpandHorizontalScroll:true,
          updateOnBrowserResize:true,
          updateOnContentResize:true,
        },
	});

    $(".catalogue-filters-inner").mCustomScrollbar({
	    axis:"y",
        scrollbarPosition: 'inside',
        scrollInertia: 150,
        scrollEasing:"linear",
        advanced:{
          autoExpandHorizontalScroll:true,
          updateOnBrowserResize:true,
          updateOnContentResize:true,
        },
	});

    $(".scroll-js").mCustomScrollbar({
	    axis:"y",
        scrollbarPosition: 'inside',
        scrollInertia: 150,
        scrollEasing:"linear",
        advanced:{
          autoExpandHorizontalScroll:true,
          updateOnBrowserResize:true,
          updateOnContentResize:true,
        },
	});
});
