import slick from "slick-carousel/slick/slick.min.js";

$(window).on('load resize', function () {
    // articles-wrap and small-recipes-wrap
    if (window.innerWidth < 1025) {
        if (!$('.articles-section .articles-wrap, .recipes .small-recipes-wrap').hasClass('slick-slider')) {
            $(".articles-section .articles-wrap, .recipes .small-recipes-wrap").slick({
                lazyLoad: 'progressive',
                dots: true,
                arrows: true,
                prevArrow: '<div class="ather-product-arrow-prev"></div>',
                nextArrow: '<div class="ather-product-arrow-next"></div>',
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                 {
                   breakpoint: 767,
                   settings: {
                       slidesToShow: 2,
                   }
                 },
                 {
                   breakpoint: 577,
                   settings: {
                       slidesToShow: 1,
                   }
                 }
               ]
            });
        }
        if (!$('.good-page .product-wrap').hasClass('slick-slider')) {

            $(".good-page .product-wrap").slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '<div class="ather-product-arrow-prev"></div>',
                nextArrow: '<div class="ather-product-arrow-next"></div>',
                infinite: false,
                responsive: [
                 {
                   breakpoint: 1025,
                   settings: {
                       slidesToShow: 3,
                   }
                 },
                 {
                   breakpoint: 767,
                   settings: {
                       slidesToShow: 2,
                   }
                 },
                 {
                   breakpoint: 577,
                   settings: {
                       slidesToShow: 1,
                   }
                 }
               ]
            })
        }
    } else {
        if ($('.articles-section .articles-wrap, .recipes .small-recipes-wrap').hasClass('slick-slider')) {
            $(".articles-section .articles-wrap, .recipes .small-recipes-wrap").slick('destroy');
        }
        if ($('.good-page .product-wrap').hasClass('slick-slider')) {
            $(".good-page .product-wrap").slick('destroy');
        }
    }
});


$(document).ready(function(){
    $('.tab-content-arrow-slider-next').click(function(){
        console.log(111)
    })
    $(".product-slick-js").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        // prevArrow: $('.tab-content-arrow-slider-prev'),
        // nextArrow: $('.tab-content-arrow-slider-next'),
        prevArrow: '<div class="arrow-prev tab-content-arrow-slider-prev"><svg viewBox="0 0 21 42" xmlns="http://www.w3.org/2000/svg"><path d="M17.3375 41.4166C16.9018 41.4181 16.4713 41.3219 16.0776 41.1351C15.6839 40.9484 15.337 40.6758 15.0625 40.3374L0.97503 22.8374C0.546042 22.3155 0.311523 21.6609 0.311523 20.9853C0.311523 20.3098 0.546042 19.6551 0.97503 19.1332L15.5584 1.63324C16.0534 1.03761 16.7648 0.663039 17.5361 0.591932C18.3073 0.520824 19.0752 0.759005 19.6709 1.25408C20.2665 1.74915 20.6411 2.46055 20.7122 3.2318C20.7833 4.00304 20.5451 4.77094 20.05 5.36657L7.01253 20.9999L19.6125 36.6332C19.9692 37.0614 20.1957 37.5827 20.2654 38.1355C20.335 38.6884 20.2449 39.2496 20.0055 39.7528C19.7662 40.256 19.3877 40.6802 18.9149 40.975C18.4421 41.2698 17.8947 41.4231 17.3375 41.4166Z"/></svg></div>',
        nextArrow: '<div class="arrow-next tab-content-arrow-slider-next"><svg viewBox="0 0 21 42" xmlns="http://www.w3.org/2000/svg"><path d="M3.66247 41.4166C4.09821 41.4181 4.52874 41.3219 4.92243 41.1351C5.31611 40.9484 5.66296 40.6758 5.93747 40.3374L20.025 22.8374C20.454 22.3155 20.6885 21.6609 20.6885 20.9853C20.6885 20.3098 20.454 19.6551 20.025 19.1332L5.44164 1.63324C4.94657 1.03761 4.23516 0.663039 3.46392 0.591932C2.69267 0.520824 1.92477 0.759005 1.32914 1.25408C0.733505 1.74915 0.358936 2.46055 0.287828 3.2318C0.216721 4.00304 0.454901 4.77094 0.94997 5.36657L13.9875 20.9999L1.38747 36.6332C1.03081 37.0614 0.804255 37.5827 0.734606 38.1355C0.664959 38.6884 0.755133 39.2496 0.994461 39.7528C1.23379 40.256 1.61226 40.6802 2.08508 40.975C2.5579 41.2698 3.10529 41.4231 3.66247 41.4166Z"/></svg></div>',
        infinite: false,
        responsive: [
         {
           breakpoint: 1025,
           settings: {
               slidesToShow: 3,
           }
         },
         {
           breakpoint: 769,
           settings: {
               slidesToShow: 2,
           }
         },
         {
           breakpoint: 569,
           settings: {
               slidesToShow: 1,
           }
         }
     ]
    })

    // product card slider
    $(".product-item-slider").slick({
        lazyLoad: 'ondemand',
        dots: false,
        arrows: true,
        prevArrow: '<div class="slick-arrow prev"><div class="slick-prev"><svg width="8" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M.191 7.355l7.076 6.132a.47.47 0 00.6 0 .333.333 0 000-.52L1.094 7.096l6.775-5.871a.333.333 0 000-.52.462.462 0 00-.598 0L.194 6.837a.332.332 0 00-.003.518z"/></svg></div></div>',
        nextArrow: '<div class="slick-arrow next"><div class="slick-next"><svg width="9" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M8.16 6.837L1.082.704a.47.47 0 00-.6 0 .333.333 0 000 .52l6.774 5.871-6.774 5.871a.333.333 0 000 .52.462.462 0 00.597 0l7.076-6.132a.332.332 0 00.003-.517z"/></svg></div></div>',
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });
    // product main slider
    $(".product-slider-big").slick({
        lazyLoad: 'progressive',
        dots: false,
        arrows: false,
        prevArrow: '<div class="ather-product-arrow-prev"></div>',
        nextArrow: '<div class="ather-product-arrow-next"></div>',
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        fade: true,
        asNavFor: ".vertical-slider",
        responsive: [
         {
           breakpoint: 1025,
           settings: {
               arrows: true,
           }
         }
       ]
    });
    $(".vertical-slider").slick({
        lazyLoad: 'progressive',
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: false,
        draggable: true,
        vertical: true,
        focusOnSelect: true,
        asNavFor: ".product-slider-big",
        prevArrow: '<div class="prev"></div>',
        nextArrow: '<div class="next"></div>',
        responsive: [
         {
           breakpoint: 1340,
           settings: {
               slidesToShow: 4,
           }
         },
         {
           breakpoint: 1120,
           settings: {
               slidesToShow: 3,
           }
         }
       ]
    });

    if($('.related_3').length) {
        $(".related_3").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '.ather-product-arrow-prev.related_3',
            nextArrow: '.ather-product-arrow-next.related_3',
            infinite: false,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 577,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        })
    }

    if($('.related_1').length) {
        var unitSlider = document.querySelectorAll('.related_1');
        $(unitSlider).each(function(){
            var $this = $(this)
                // $arrowprev = $this.closest('.ather-product-gallery-wrap').find('.ather-product-arrow-prev.related_1'),
                // $arrownext = $this.closest('.ather-product-gallery-wrap').find('.ather-product-arrow-next.related_1');
                $this.slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    // prevArrow: $arrowprev,
                    // nextArrow: $arrownext,
                    prevArrow: '<div class="arrow-prev tab-content-arrow-slider-prev"><svg viewBox="0 0 21 42" xmlns="http://www.w3.org/2000/svg"><path d="M17.3375 41.4166C16.9018 41.4181 16.4713 41.3219 16.0776 41.1351C15.6839 40.9484 15.337 40.6758 15.0625 40.3374L0.97503 22.8374C0.546042 22.3155 0.311523 21.6609 0.311523 20.9853C0.311523 20.3098 0.546042 19.6551 0.97503 19.1332L15.5584 1.63324C16.0534 1.03761 16.7648 0.663039 17.5361 0.591932C18.3073 0.520824 19.0752 0.759005 19.6709 1.25408C20.2665 1.74915 20.6411 2.46055 20.7122 3.2318C20.7833 4.00304 20.5451 4.77094 20.05 5.36657L7.01253 20.9999L19.6125 36.6332C19.9692 37.0614 20.1957 37.5827 20.2654 38.1355C20.335 38.6884 20.2449 39.2496 20.0055 39.7528C19.7662 40.256 19.3877 40.6802 18.9149 40.975C18.4421 41.2698 17.8947 41.4231 17.3375 41.4166Z"/></svg></div>',
                    nextArrow: '<div class="arrow-next tab-content-arrow-slider-next"><svg viewBox="0 0 21 42" xmlns="http://www.w3.org/2000/svg"><path d="M3.66247 41.4166C4.09821 41.4181 4.52874 41.3219 4.92243 41.1351C5.31611 40.9484 5.66296 40.6758 5.93747 40.3374L20.025 22.8374C20.454 22.3155 20.6885 21.6609 20.6885 20.9853C20.6885 20.3098 20.454 19.6551 20.025 19.1332L5.44164 1.63324C4.94657 1.03761 4.23516 0.663039 3.46392 0.591932C2.69267 0.520824 1.92477 0.759005 1.32914 1.25408C0.733505 1.74915 0.358936 2.46055 0.287828 3.2318C0.216721 4.00304 0.454901 4.77094 0.94997 5.36657L13.9875 20.9999L1.38747 36.6332C1.03081 37.0614 0.804255 37.5827 0.734606 38.1355C0.664959 38.6884 0.755133 39.2496 0.994461 39.7528C1.23379 40.256 1.61226 40.6802 2.08508 40.975C2.5579 41.2698 3.10529 41.4231 3.66247 41.4166Z"/></svg></div>',
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1025,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 577,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                })
        })
    }

    // recipe-slide

    if($('.recipe-gallery-slider').length) {
        var unitSlider = document.querySelectorAll('.recipe-gallery-slider');
        $(unitSlider).each(function(){
            var $this = $(this),
                $arrowprev = $this.closest('.recipe-gallery-wrap').find('.recipe-gallery-prev'),
                $arrownext = $this.closest('.recipe-gallery-wrap').find('.recipe-gallery-next');
                $this.slick({
                    centerMode: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: $arrowprev,
                    nextArrow: $arrownext,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 1025,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 577,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                })
        })
    }

    if($('.related_2').length) {
        $(".related_2").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '.ather-product-arrow-prev.related_2',
            nextArrow: '.ather-product-arrow-next.related_2',
            infinite: false,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 577,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        })
    }

    $(".series-gallery-slick").slick({
        lazyLoad: 'ondemand',
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '.ather-product-arrow-prev.series-gallery-slick',
        nextArrow: '.ather-product-arrow-next.series-gallery-slick',
        infinite: false,
        responsive: [
         {
           breakpoint: 767,
           settings: {
               slidesToShow: 2,
           }
         },
         {
           breakpoint: 577,
           settings: {
               slidesToShow: 1,
           }
         }
       ]
    })

    if($('.gallery-video').length) {
        var videoSlider = document.querySelectorAll('.gallery-video');
        $(videoSlider).each(function(){
            var $this = $(this),
                $arrowprev = $this.closest('.gallery-video-wrap').find('.arrow-prev'),
                $arrownext = $this.closest('.gallery-video-wrap').find('.arrow-next');
                $this.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: $arrowprev,
                    nextArrow: $arrownext,
                    infinite: false,
                })
        })
    }

});
