$(document).ready(function() {
    // modals
    function reloadTopCart() {
        $.ajax({
            type: "POST",
            url: routes.postReloadTopCart,
            data: {},
            dataType: "json",
            success: function(data) {
                $("#top_cart").empty().html(data.top)
            }
        })
    };

    function showEditOrder () {
        $.ajax ({
            type: "POST",
            url: routes.postReloadOrderCart,
            dataType: "json",
            data: {
                "lang": $('html').attr('lang'),
            },
            beforeSend: function(){
                $('#spin').show();
            },
    		success: function(data) {
                $('#spin').hide();
                $("#order_cart").empty().html(data.body);
    		}
    	});
    }

    function monoPaymentStatus() {
        $.ajax({
            type: "POST",
            url: routes.postMonoPaymentStatus,
            data: {},
            dataType: "json",
        })
    };

    $(document).ready(function() {
        // покупка монобанк
        $(document).on('click', ".js-to-cart-mono", function () {
            var lang = $('html').attr('lang');
            var btn = $(this);
            var good_id = $(this).attr('data-id');
            var count = $(this).attr('data-count');
            var mono = true;
            var el = $(this);
            $.ajax ({
                type: "POST",
                url: routes.postMonobankGoodOrder,
                data: {
                    "good_id": good_id,
                    "count": count,
                    "mono": mono,
                    "lang": lang,
                },
                success: function(data) {
                    window.location.href = data;
                }
            });
        });
        // добавление в корзину
        var lang = $('html').attr('lang');
        var qty;
        var ajax_url;
        $(document).on( 'click', '.js-to-cart', function(e){
            var btn = $(this);
            var langInCart = btn.attr('data-in-cart');
            var good_id = $(this).attr('data-id');
            var count = $(this).attr('data-count');
            var el = $(this);
            if(el.hasClass('in_cart')){
                ajax_url = routes.postShowGoodOrder;
            } else if (el.hasClass('add-to-cart')) {
                ajax_url = routes.postReloadGoodOrder;
            } else {
                ajax_url = routes.postReloadGoodOrder;
            }
            $(".good-modal-wrapper").empty();
            $.ajax ({
                type: "POST",
                url: ajax_url,
                beforeSend: function(){
                    $('#spin').show();
                },
                data: {
                    "good_id": good_id,
                    "count": count,
                    "lang": lang,
                },
                success: function(data) {
                    $('#spin').hide();
                    reloadTopCart();
                    if (btn.hasClass('orange-btn')) {
                        btn.text(langInCart);
                        btn.addClass('in_cart').removeClass('add-to-cart');
                    } else if (btn.hasClass('red-btn')) {
                        btn.text(langInCart);
                        btn.addClass('in_cart').removeClass('add-to-cart');
                    } else if (btn.hasClass('dot-good-btn')) {
                        btn.addClass('in_cart').removeClass('add-to-cart');
                    }
                    $(".good-modal-wrapper").html(data);
                    showEditOrder ();
                    monoPaymentStatus();
                },
            });
        });
    });

    $(document).on( 'click', '.open-modal-js', function(e){
        var popup = $(this).attr('data-popup');
        $(document).one('keydown', function(e) {
            if (e.keyCode == 27) {
                $('.popup').removeClass('open');
                $('.side-popup').removeClass('open');
                $('body').removeClass('hidden');
            }
        });

        if($(e.target).is('.quick-buy-btn') || $(e.target).is('.get_price') || $(e.target).is('.get_good') || $(e.target).is('.get_good-mobile') || $(e.target).is('.get_price-mobile')) {
            var id = $(this).attr('data-id');
            $('.' + popup).find('.value_id').val(id);
        } else if($(e.target).is('.add-to-cart')) {
            var id = $(this).attr('data-id');
            $('.' + popup).find('.value_id').val(id);
            var count = $(this).attr('data-count');
            $('.' + popup).find('.count').val(count);
        };

        if($('.popup').is('.open')) {
            $('.popup').removeClass('open');
            $('body').removeClass('hidden');
        };

        if($('.side-popup').is('.open')) {
            $('.side-popup').removeClass('open');
            $('body').removeClass('hidden');
        };

        $('.' + popup).addClass('open');
        // $('.' + popup).find(".form-control")[0].focus();
        $('body').addClass('hidden');

        $('.popup-close').click(function () {
            $('.' + popup).removeClass('open');
            $('body').removeClass('hidden');
        });
    });
    // close all popup click overlay
    $(document).click(function(e) {
        var el = $(e.target);
        if (el.is(".popup.open") || el.is(".side-popup.open")) {
            el.removeClass('open');
            $('body').removeClass('hidden');
        }
    });
});
