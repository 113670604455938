var body = $('body');

// $(window).on('load', function () {
//    $('#preloader').delay(350).fadeOut('slow');
// });


// footer submenu

$(document).on('click', '.footer-link-js', function(e){
    var submenu = $(this).closest('.footer-item').find('.footer-item-nav');
    var icon =  $(this).closest('.footer-item').find('.footer-item-icon');
    $(this).toggleClass('active');
    if($(this).hasClass('active')) {
        icon.removeClass('plus').addClass('minus');
        submenu.slideDown(200);
    } else {
        icon.removeClass('minus').addClass('plus');
        submenu.slideUp(200);
    }
});

$(document).on('click', '.js_open_reviews_form', function(e){
    $(this).addClass('js_hide');
    $('.good_reviews__form-cover').slideDown(400, function(){
      $(this).removeClass('js_hide');
    });
});



$(document).ready(function() {
    // fix 100vh chrome
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if($('.header-scroll-js').length) {
        $('.header-scroll-js').on('click', function(e) {
            var scroll_el = $(this).attr('data-scroll');
            if(scroll_el == 'components') {
                $('html, body').animate({ scrollTop: $("#"+scroll_el).offset().top - 80 }, 1000);
            } else {
                $('html, body').animate({ scrollTop: $("#"+scroll_el).offset().top - 140 }, 1000);
            }
        })
    }

    // open all recipes
    if($('.all-recipes-js').length) {
        $('.all-recipes-js').click(function(e) {
            var diploms_list = $(this).closest('.recipes-btn-wrap').prev();
            diploms_list.find('.articles-holder:not(.visible)').slideToggle(function() {
                diploms_list.find('articles-holder:not(.visible)').toggleClass('hide');
            });
            $(this).children('.visible-text').toggleClass('text-hide');
            $(this).children('.hide-text').toggleClass('text-hide');
        });
    }

    $('.nav-menu-column').matchHeight({
        byRow: false,
    });


    $('.header-open-top-search-form').one('click', function(e) {
        $('.header-open-top-search-form').css('display', 'none');
        $('.header-top-search-form').find(".input-search").addClass('active');
        $('.header-top-do-search').addClass('active');
        $('.header-top-search-form').addClass('open');
        $('.header-top-search-form').find(".input-search").focus();
    })

    $('.header-search-block').on('click', function(e) {
        var formWrap = $(this).closest('.header-contact-block-top').next();
        $(this).fadeOut();
        formWrap.slideToggle();
        formWrap.find(".input-search").focus();
    })

    $('.back-menu-js').on('click', function(e) {
        e.stopPropagation();
        $('.nav-menu-wrap').removeClass('translate');
        $('.open-cat-menu-js').removeClass('open');
        $('.cat-nav-menu-wrap').css('display','none').removeClass('active');
        $('.cat-nav-icon-list').removeClass('show-border');
        $('.header-contact-block-inner').removeClass('show-border');
    });

    $('.open-cat-menu-js').on('click', function(e) {
        var element = $(this);
        if (window.innerWidth < 577) {
            $('.nav-menu-wrap').addClass('translate');
        }
        if(!element.hasClass('open')) {
            $('.open-cat-menu-js').removeClass('open');
            $('.cat-nav-menu-wrap').css('display','none').removeClass('active');
            $('.cat-nav-icon-list').removeClass('show-border');
            $('.header-contact-block-inner').removeClass('show-border');
            element.addClass('open');
            element.find('.cat-nav-menu-wrap').fadeIn(300).addClass('active');
            setTimeout(function(){
                if (element.hasClass('nav-menu-unit')) {
                    element.find('.header-contact-block-inner').addClass('show-border');
                } else {
                    element.find('.cat-nav-icon-list').addClass('show-border');
                }
            }, 500);
        }
    })

    // recipes accordeon
    if($('.recipe-name').length) {
        $('.recipe-name').on('click', function(e) {
            var submenu = $(this).closest('.recipe-item').find('.recipe-desc');
            var icon =  $(this).closest('.recipe-item').find('.recipe-name');
            $(this).toggleClass('active');
            if($(this).hasClass('active')) {
                icon.addClass('active');
                submenu.slideDown(200);
            } else {
                icon.removeClass('active');
                submenu.slideUp(200);
            }
        });
    }

    // scrollTop
    $('.up_button').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });
    // position sticky
    Stickyfill.forceSticky()
	Stickyfill.add(document.querySelectorAll('[data-sticky]'));

    // polifil object-fit
    if($('.object-fit-js').length) {
       var someImages = $('.object-fit-js');
       objectFitPolyfill(someImages);
    }

    // breadcrumbs
    $(".js-lastcrumb").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
    })
    // share open
    $('.share-toggle-btn').click(function() {
        $(this).closest('.share-block').find('.share-socials-wrap').toggleClass('active');
    });

    // rename button to-cart
    function goodBuy() {
        $('.js-to-cart').each(function(e) {
          if ($(this).hasClass("add-product-cart")) {
            var addcart = $(this).attr('data-to-cart');
            if (!$(this).is('.good-sticky-buy-btn-mobile')) {
                $(this).text(addcart);
            }
          } else if ($(this).hasClass("in_cart")) {
              var incart = $(this).attr('data-in-cart');
              if (!$(this).is('.good-sticky-buy-btn-mobile')) {
                  $(this).text(incart);
              }
            }
        });
    }
    goodBuy();

    // open mobile menu
    $('.burger-menu').on('click', function() {
        $('.menu__icon').toggleClass('open');
        $('.header-nav').toggleClass('open');
        $('body').toggleClass('overflow');
    });
    // open new mobile menu
    $('.new-burger-menu').on('click', function() {
        $('.menu-icon').toggleClass('open');
        $('.header-menu').toggleClass('open');
        $('body').toggleClass('overflow');
        // for close menu
        $('.open-cat-menu-js').removeClass('open');
        $('.cat-nav-menu-wrap').css('display','none').removeClass('active');
        if($('.nav-menu-wrap').hasClass('translate')){
            $('.nav-menu-wrap').removeClass('translate');
        }
    });
    // selectric init
    $('.sort_wrap').selectric({
        disableOnMobile: false,
        nativeOnMobile: false,
    })
    // address toggle
    $(".address_toggler").change(function (e) {
        if ($(this).is(":checked")) {
            $(".address_variants").slideUp(400);
            $(".address_variants").find('.form-control-js').each(function() {
                $(this).prop('disabled', true);
            });
            $("#"+$(this).val()).slideDown(400);
            $("#"+$(this).val()).find('.form-control-js').each(function() {
                $(this).prop('disabled', false);
            });
        }
    });
    // input count
    $('.set_count').click(function(e) {
		var input = $(this).closest('.cart-product-item').find('.count');
		var value = $(this).attr('data-value');
		if (value == 'up'){
			var new_count = parseInt(input.val()) + 1;
		} else {
			if (parseInt(input.val()) > 1){
				var new_count = parseInt(input.val()) - 1;
			} else {
				var new_count = 0;
			}
		}
		input.val(new_count);
		input.trigger("change");
	});
    // breadcrumbs
    $(".js-lastcrumb").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
	})
    // tabs
    if($('.tabs-product-wrap').length) {
        var active_tab = $('.tabs-product-wrap .tabs li:first-child');
        active_tab.addClass('active');
        $('.tabs-product-wrap .tab-content').hide();
        var selectTab = active_tab.attr('data-tab');
        $('#'+selectTab).fadeIn();
    }
    $('.tabs li').click(function(e) {
    	var parent = $(this).closest('.tabs-container');
        parent.find('.tabs li').removeClass('active');
        $(this).addClass('active');
        parent.find('.tab-content').hide();
        var selectTab = $(this).attr('data-tab');
        $('#'+selectTab).fadeIn();
        if ($('#'+selectTab).find('.product-item-slider').is('.slick-slider') == true) {
            $(".product-item-slider").slick('destroy');
            $(".product-item-slider").slick('refresh');
        }
        if ($('#'+selectTab).find('.product-wrap').is('.slick-slider') == true) {
            $(".product-wrap").slick('destroy');
            $(".product-wrap").slick('refresh');
        }
        if ($('#'+selectTab).find('.small-recipes-wrap').is('.slick-slider') == true) {
            $(".small-recipes-wrap").slick('destroy');
            $(".small-recipes-wrap").slick('refresh');
        }
    });

    $(".product-item").not(".ather-product-gallery-wrap .product-item").each(function() {
        $(this).hover(function() {
            $(this).addClass("active");
            $(this).find(".features").slideDown("fast");
        }, function() {
            $(this).removeClass("active");
            $(this).find(".features").hide();
        });
    });

    // -------------------- Звезды на странице товара--------------------------
  $(".js_rating").each(function() {
    $(this).rateYo({
      rating: $(this).attr('data-rate'),
      starWidth: $(this).attr('data-width'),
      fullStar: true,
      spacing: "5px",
      starSvg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" fill="none"><path d="M6 5L5.9 5.2 5.6 5.2 1.1 5.9C1.1 5.9 1.1 5.9 1.1 5.9 1.1 5.9 1.1 5.9 1.1 6L1.1 6 1.1 6C1.1 6 1.1 6 1.1 6 1.1 6 1.1 6 1.1 6L4.3 9.2 4.5 9.4 4.5 9.6 3.7 14.1 3.7 14.1C3.7 14.1 3.7 14.2 3.8 14.2L3.8 14.2C3.8 14.2 3.8 14.2 3.8 14.2L3.8 14.2 7.8 12.1 8.1 11.9 8.3 12.1 12.3 14.2 12.3 14.2C12.3 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.1 12.4 14.1 12.4 14.1 12.4 14.1 12.4 14.1L12.4 14.1 11.7 9.6 11.6 9.4 11.8 9.2 15.1 6 15.1 6C15.1 6 15.1 6 15.1 6 15.1 5.9 15.1 5.9 15.1 5.9 15 5.9 15 5.9 15 5.9L15 5.9 10.5 5.2 10.3 5.2 10.1 5 8.1 0.9C8.1 0.9 8.1 0.9 8.1 0.9 8.1 0.9 8.1 0.8 8 0.9L8 0.9C8 0.9 8 0.9 8 0.9L8 0.9 6 5Z" stroke="#E38B22"/></svg>',
      normalFill: "#ffffff",
      ratedFill: "#E38B22",
      spacing: "4px",
      readOnly: true,
    });
});

$(".rateYo").each(function() {
       $(this).rateYo({
           rating: $(this).attr('data-rate'),
           starWidth: "22px",
           fullStar: true,
           spacing: "5px",
           starSvg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" fill="none"><path d="M6 5L5.9 5.2 5.6 5.2 1.1 5.9C1.1 5.9 1.1 5.9 1.1 5.9 1.1 5.9 1.1 5.9 1.1 6L1.1 6 1.1 6C1.1 6 1.1 6 1.1 6 1.1 6 1.1 6 1.1 6L4.3 9.2 4.5 9.4 4.5 9.6 3.7 14.1 3.7 14.1C3.7 14.1 3.7 14.2 3.8 14.2L3.8 14.2C3.8 14.2 3.8 14.2 3.8 14.2L3.8 14.2 7.8 12.1 8.1 11.9 8.3 12.1 12.3 14.2 12.3 14.2C12.3 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.2 12.4 14.1 12.4 14.1 12.4 14.1 12.4 14.1 12.4 14.1L12.4 14.1 11.7 9.6 11.6 9.4 11.8 9.2 15.1 6 15.1 6C15.1 6 15.1 6 15.1 6 15.1 5.9 15.1 5.9 15.1 5.9 15 5.9 15 5.9 15 5.9L15 5.9 10.5 5.2 10.3 5.2 10.1 5 8.1 0.9C8.1 0.9 8.1 0.9 8.1 0.9 8.1 0.9 8.1 0.8 8 0.9L8 0.9C8 0.9 8 0.9 8 0.9L8 0.9 6 5Z" stroke="#E38B22"/></svg>',
           normalFill: "#e8e7e7",
           ratedFill: "#E38B22",
           spacing: "4px",
           onSet: function (rating, rateYoInstance) {
               $('.js-product-rating').val(rating);
           }
       });
   });
// -------------------- Звезды на странице товара--------------------------
// -------------------Скролл и клик до блока отзывы ------------------------
$(document).on( 'click', '.js_product-article-reviews_top', function(e){
    $('.tabs li[data-tab="reviews"]').click();
    var scroll= $('.tabs li[data-tab="reviews"]').offset().top - 80;
    $('html, body').animate({ scrollTop: scroll }, 1000);
    $(".tabs-product-wrap .tabs").mCustomScrollbar('scrollTo', $('.tabs li[data-tab="reviews"]'));
});
// -------------------Скролл и клик до блока отзывы ------------------------

    // accordion
    $(".accordion-name").on("click", function() {
	    if ($(this).hasClass("active")) {
	      $(this).removeClass("active");
	      $(this).siblings(".content").slideUp(200);
	      $(".accordion-icon").removeClass("active");
	    } else {
	      $(".accordion-icon").removeClass("active");
	      $(this).find(".accordion-icon").addClass("active");
	      $(".accordion-name").removeClass("active");
	      $(this).addClass("active");
	      $(".content").slideUp(200);
	      $(this).siblings(".content").slideDown(200);
	      }
	});
    //   open search modal
    $('.header-search').click(function() {
        $(this).toggleClass('active');
        $('.search-dropdown').toggleClass('active');

        setTimeout(function(){
            $('.search-dropdown').find(".input-search").focus();
        }, 300);
        // $('body').toggleClass('hidden');
        $(".search-close").click(function() {
            $(".search-dropdown").removeClass("active");
            if($('.header-search').is('.active')) {
                $('.header-search').removeClass("active");
            }
            // if($('body').is('.hidden')) {
            //     body.removeClass("hidden");
            // }
        });
    });

    //   find iframe
    $(".description").find('iframe').each(function() {
        $(this).closest('p').addClass('iframe-holder').wrap("<div class='iframe-container'></div>");
    });
    // find
    $('.do_search').click(function() {
        $('.input_search').validate();
        var search = $('.input_search').value;
        location.href = 'search?search=' + search
    });
    // sort goods_list
    $('.sort_wrap').change(function() {
        $('#sortTrigger').submit();
    })

    $('.removeFromCompare').click(function(e){
        e.preventDefault();
        var good_id = $(this).attr('id');
        var good_cat = $(this).attr('data-id');
        $.ajax({
            url:routes.postDelCompare,
            type:'post',
            data: {
                good_id  : good_id,
                good_cat : good_cat
            },
            success:function(){
                location.reload();
            }
        })
    });

});


$(window).on('load resize', function() {
    if (window.innerWidth < 1025) {
        // $('scroll-js').mCustomScrollbar('destroy');

        // for mobile footer
        if (!$(".footer-item-name").hasClass('footer-link-js')) {
            $(".footer-item-name").addClass('footer-link-js');
            $('.footer-item-nav').css('display', 'none');
        }

        var btn = $('.menu__icon');
        if(btn.hasClass('open')) {
            btn.trigger('click');
        }
        // open side sort block
        $(document).on( 'click', '.form-name-block', function(e){
            if (!$('.catalogue-list-filters').hasClass('filter-open')) {
               $('.catalogue-list-filters').addClass('filter-open');
               $('body').addClass('overflow');
               $('.form-name-block').addClass('open');
             } else {
               $('.catalogue-list-filters').removeClass('filter-open');
                $('body').removeClass('overflow');
                $('.form-name-block').removeClass('open');
             };
        });
    } else {
        // $('scroll-js').mCustomScrollbar('update');
        // for mobile footer
        if ($(".footer-item-name").hasClass('footer-link-js')) {
            $(".footer-item-name").removeClass('footer-link-js');
            $(".footer-item-name").removeClass('active');
            $('.footer-item-nav').css('display', 'block');
            if ($('.footer-item-icon').hasClass('minus')) {
                $('.footer-item-icon').removeClass('minus');
                $('.footer-item-icon').addClass('plus');
            }
        }

        var filter_btn = $('.form-name-block');
        if(filter_btn.hasClass('open')) {
             $('.catalogue-list-filters').removeClass('filter-open');
             $('body').removeClass('overflow');
             $('.form-name-block').removeClass('open');
        }
    }

    // if (window.innerWidth < 861) {
    //     $('.add-product-cart-small-wrap').insertAfter( $('.footer-side-popup') );
    // } else {
    //     $('.add-product-cart-small-wrap').insertBefore( $('.popup-content') );
    // }


    if (window.innerWidth < 769) {
        // отключение расчета выстоты карточки в гуд листе
        // $('.catalogue-list-item').matchHeight({ remove: true });
        if($('.good-page').length) {
            $('.product-slider').insertAfter( $( ".product-title" ) );
            $('.product-article').insertAfter( $( ".product-title" ) );
        }
   } else {
       if($('.good-page').length) {
           $('.product-slider').insertBefore( $( ".product-data" ) );
           $('.product-article').insertBefore( $( ".product-title" ) );
       }
   }

   if (window.innerWidth > 576) {
       if ($('.nav-menu-wrap').hasClass('translate')) {
           $('.nav-menu-wrap').removeClass('translate');
           $('.open-cat-menu-js').removeClass('open');
           $('.cat-nav-menu-wrap').css('display','none').removeClass('active');
           $('.cat-nav-icon-list').removeClass('show-border');
           $('.header-contact-block-inner').removeClass('show-border');
       }
   }

});

//   fixed scroll
$(window).on('load scroll', function() {
    // button scroll start
    if ($(window).scrollTop() > 100) {
         $('.up_button').addClass("visible");
    } else {
         $('.up_button').removeClass("visible");
    };
    // button scroll end

    if($('.good-page').length) {
        var goodSticky = $('.good-sticky-block');
        var positionBtnBlock = $('.product-price-block').offset().top;
        if ($(window).scrollTop() >= positionBtnBlock) {
            if (!goodSticky.hasClass('active')) {
                goodSticky.slideDown(300);
                goodSticky.addClass('active');
            }
        } else {
            if (goodSticky.hasClass('active')) {
                goodSticky.slideUp(300);
                goodSticky.removeClass('active');
            }
        }
    }

    //    fixed header
    var fixHeaderBlock = $(".fix-on-scroll");
    if (window.innerWidth < 1025) {
        if (!fixHeaderBlock.hasClass('fixed')) {
            fixHeaderBlock.addClass("fixed");
            $(body).addClass("fixed-nav");
        }
   } else {
        if ($(window).scrollTop() >= 40) {
            if (!fixHeaderBlock.hasClass('fixed')) {
                fixHeaderBlock.addClass("fixed");
                $(body).addClass("fixed-nav");
            }
        } else {
            if (fixHeaderBlock.hasClass('fixed')) {
                fixHeaderBlock.removeClass("fixed");
                $(body).removeClass("fixed-nav");
            }
        }
   }
   //    fixed new-header
   var newHeader = $(".new-header");
   if ($(window).scrollTop() >= 40) {
       if (!newHeader.hasClass('header-small')) {
           newHeader.addClass("header-small");
        //    $(body).addClass("fixed-nav");
       }
   } else {
       if (newHeader.hasClass('header-small')) {
           newHeader.removeClass("header-small");
        //    $(body).removeClass("fixed-nav");
       }
   }
});

// COMPARE GROUP SCRIPT

$(document).on( 'click', '.good-card_compare', function(e){
    var lang = $("html").attr('lang');
    var good_id = $(this).attr('id');
    var good_cat = $(this).attr('data-id');
    $.ajax({
        url: routes.postSetCompare,
        type: 'post',
        dataType: 'json',
        data: {
            good_id  : good_id,
            good_cat : good_cat,
            lang: lang,
        },
        success: function(data){
            $('.top-compare.countered').empty().html(data.result.compare);
            // подмена кнопки в карточке товара
            var dataId = $('.product-item').find('.compare-action-btn[data-id="'+ data.result.id +'"]');
            $(dataId).empty();
            $(dataId).append(data.result.icon);
            // подмена кнопки на странице товара
            if($('.good-page').length) {
                var btnCompareProductPage = $('.compare-action-btn[data-id="'+ data.result.id +'"]');
                $(btnCompareProductPage).empty();
                $(btnCompareProductPage).append(data.result.icon);
            } else {
                var btnCompareProductPage = $('.compare-action-btn[data-id="'+ data.result.id +'"]');
                $(btnCompareProductPage).empty();
                $(btnCompareProductPage).append(data.result.icon);
            }
        }
    })
});
$(document).on( 'click', '.removeListCompare', function(e){
    var lang = $("html").attr('lang');
    var good_id = $(this).attr('id');
    var good_cat = $(this).attr('data-id');
    $.ajax({
        url:routes.postDelCompare,
        type:'post',
        data: {
            good_id  : good_id,
            good_cat : good_cat,
            lang: lang,
        },
        success:function(data){
            $('.top-compare.countered').empty().html(data.result.compare);
            var dataId = $('.product-item').find('.compare-action-btn[data-id="'+ data.result.id +'"]');
            $(dataId).empty();
            $(dataId).append(data.result.icon);
            // $('.add-compare-js').find('.removeListCompare').remove();
            // $('.add-compare-js').append(data.result.icon);
            if($('.good-page').length) {
                var btnCompareProductPage = $('.compare-action-btn[data-id="'+ data.result.id +'"]');
                $(btnCompareProductPage).empty();
                $(btnCompareProductPage).append(data.result.icon);
            } else {
                var btnCompareProductPage = $('.compare-action-btn[data-id="'+ data.result.id +'"]');
                $(btnCompareProductPage).empty();
                $(btnCompareProductPage).append(data.result.icon);
            }
        }
    })
});
