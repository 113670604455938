require('../../../node_modules/jquery/dist/jquery.min');
window.$ = window.jQuery = require('jquery');

// nouislider
import noUiSlider from "nouislider/distribute/nouislider.min";
window.noUiSlider = require('nouislider');
// wNumb
import wNumb from "wnumb/wNumb.min";
window.wNumb = require('wnumb');

// form Stickyfill
import Stickyfill from "stickyfilljs/dist/stickyfill.min";
window.Stickyfill = require('stickyfilljs');

// object-fit-images
import objectFitPolyfill from 'objectFitPolyfill';

// form validate
import validate from "jquery-validation";
// slick
require('./slick');
// input mask
require('./input_mask');
// forms
require('./forms');
// all_popup
require('./all_popup');
// magnific_popup
require('./magnific_popup');
// selectric
import selectric from "selectric";
// matchHeight
require('./matchHeight');
// malihu_scrollbar
require('./malihu_scrollbar');
// Reviews raiting
require('rateyo');
// lightcase
// require('./lightcase');
// lazyloadxt
// require('./lazyloadxt');

// lazysizes
import 'lazysizes';

// compare
require('./compare');
// main
require('./main');
