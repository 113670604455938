// validate form callback
$(".do_callback_form").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.callback_form');
    formCallbackValidate(formVal);
    formVal.submit();
});
function formCallbackValidate(form) {
    form.validate({
        submitHandler: function(form) {
          var csrf_token = $('meta[name="csrf-token"]').attr('content');
          var formdata = $(form).serialize();
          $(form)[0].reset();
          $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                "subj": "callback"
            },
            success: function(data) {
                $(form).hide();
                $(form).next('.form-thanks').show();
                function hidePopup(){
                    $('.callback').removeClass('open');
                    $('body').removeClass('hidden');
                }
                function showForm(){
                    $(form).next('.form-thanks').hide();
                    $(form).show();
                }
                setTimeout( hidePopup ,5000);
                setTimeout( showForm ,5000);
            }
          });
        }
    });
}
// new-post-form start
function formValidateNewPost(form) {
    form.validate({
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "subscription"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( showForm ,5000);
                }
            })
        }
    });
};
$(".do-new-post-form").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.new-post-form');
    formValidateNewPost(formVal);
    formVal.submit();
});
// new-post-form end

// quick form start
function formValidateQuickOrder(form) {
    form.validate({
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postFastOrder,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    // "subj": "Изменить!!!!!!"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $(form).closest('.quick-order').removeClass('open');
                        $('body').removeClass('hidden');
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,5000);
                    setTimeout( showForm ,5000);
                }
            })
        }
    });
};
$(".do_quick-order").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.quick-order_form');
    formValidateQuickOrder(formVal);
    formVal.submit();
});
// quick form end

// video consultation start
function formValidateVideoConsultation(form) {
    form.validate({
        errorPlacement: function(error, element) {
            if ( element.attr("type") == "radio" ) {
                error.appendTo( element.parents('.apps-wrap') );
            } else {
                error.insertAfter( element );
            }
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "consultation"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $('.video_consultation').removeClass('open');
                        $('body').removeClass('hidden');
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,5000);
                    setTimeout( showForm ,5000);
                }
            })
        }
    });
};
$(".do_video_consultation").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.video_consultation');
    formValidateVideoConsultation(formVal);
    formVal.submit();
});
// video consultation end

// feedback-form start
$(".feedback-form").validate({
    submitHandler: function(form) {
      var csrf_token = $('meta[name="csrf-token"]').attr('content');
      var formdata = $(".feedback-form").serialize();
      $(".feedback-form")[0].reset();
      $.ajax({
        url: routes.postSend,
        type: 'POST',
        data: {
            "_token" : csrf_token,
            "data": formdata,
            "subj": "feedback"
        },
        success: function(data) {
            $(form).hide();
            $(form).next('.form-thanks').show();
            function showForm(){
                $(form).next('.form-thanks').hide();
                $(form).show();
            }
            setTimeout( showForm ,5000);
        }
      });
    }
});

$(".do-feedback-form").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $(".feedback-form").submit();
});
// feedback-form end

// get_price start
function formValidateGetPrice(form) {
    form.validate({
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "price_request"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $('.get_price_popup').removeClass('open');
                        $('body').removeClass('hidden');
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,5000);
                    setTimeout( showForm ,5000);
                }
            })
        }
    });
};
$(".do_get_price").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.get_price_form');
    formValidateGetPrice(formVal);
    formVal.submit();
});
// get_price end

// get_good start
function formValidateGetGood(form) {
    form.validate({
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "good_request"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $('.get_good_popup').removeClass('open');
                        $('body').removeClass('hidden');
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,5000);
                    setTimeout( showForm ,5000);
                }
            })
        }
    });
};
$(".do_get_good").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var formVal = $(this).closest('.get_good_form');
    formValidateGetGood(formVal);
    formVal.submit();
});
// get_good end


// оформление заказа

function formValidateDoOrder(form) {
    form.validate({
        ignore: ':hidden:not(select)',
        errorPlacement: function(error, element) {
            // for error selectric
            var data = element.data('selectric');
            error.appendTo( data ? element.closest( '.' + data.classes.wrapper ).parent() : element.parent() );
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $(form).serialize();
            $(form)[0].reset();
            $.ajax({
                url: routes.postDoOrder,
                type: 'POST',
                beforeSend: function(){
                    $('#spin').show();
                },
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                dataType: "json",
                success: function(data) {
                    if (data.status === "liqpay") {
						var $form = $(data.liqpay_form);
						$('body').append($form);
						$form.submit();
					} else if (data.status === "ok") {
                        $('#spin').hide();
                        $('html, body').animate({ scrollTop: $("header").offset().top});
                        $(form).hide();
                        $('.main-section-title').hide();
                        $('.ordering-block').hide();
                        $('.form-thanks').show();
                        function toHome(){
                            window.location.href = "/";
                        }
                        setTimeout( toHome ,5000);
                    }
                }
            })
        }
    });
};
$("#do_order_btn").click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    if ($('#agree-id-3').prop('checked')){
        var formVal = $(this).closest('.order_form');
        formValidateDoOrder(formVal);
        formVal.submit();
    } else{
       $('.text-agreement').css('display','none');
       $('.text-disagree').css('display','block');
    }


});
$('#agree-id-3').on('change', function(){
    if ($('#agree-id-3').prop('checked')){
        $('.text-agreement').css('display','block');
        $('.text-disagree').css('display','none');
        $('#do_order_btn').removeClass('disabled');
    } else{
        $('.text-agreement').css('display','none');
        $('.text-disagree').css('display','block');
        $('#do_order_btn').addClass('disabled');
    }
});
// оформление заказа

// вход в кабинет
// function formSignIn(form) {
//     form.validate({
//         submitHandler: function(form) {
//             var csrf_token = $('meta[name="csrf-token"]').attr('content');
//             var formdata = $(form).serialize();
//             $(form)[0].reset();
//             $.ajax({
//                 url: routes.postLogin,
//                 type: 'POST',
//                 data: {
//                     "_token" : csrf_token,
//                     "data": formdata,
//                 },
//                 success: function(data) {
//                     if (data == 'success') {
//                         window.location.reload(true);
//                     } else if (data == 'wrong_pass') {
//                         $(".registration .auth-error").slideDown(200);
//                     }
//                 }
//             })
//         }
//     });
// };
// $(".registration-btn").click(function(e) {
//     e.stopPropagation();
//     e.preventDefault();
//     var formVal = $(this).closest('.registration_form');
//     formSignIn(formVal);
//     formVal.submit();
// });

//регистрация
// function formRegistration(form) {
//     form.validate({
//         submitHandler: function(form) {
//             var csrf_token = $('meta[name="csrf-token"]').attr('content');
//             var formdata = $(form).serialize();
//             console.log(formdata);
//             $(form)[0].reset();
//             $.ajax({
//                 url: routes.postRegister,
//                 type: 'POST',
//                 data: {
//                     "_token" : csrf_token,
//                     "data": formdata,
//                 },
//                 success: function(data) {
//
//                 }
//             })
//         }
//     });
// };
// $(".do_sign_in").click(function(e) {
//     e.stopPropagation();
//     e.preventDefault();
//     var formVal = $(this).closest('.sign_in_form');
//     formRegistration(formVal);
//     formVal.submit();
// });

// выход из кабинета
// $(".cabinet-js-logout").click(function(e) {
//     e.stopPropagation();
//     e.preventDefault();
//     $.ajax({
//         url: routes.postLogout,
//         type: 'POST',
//         success: function(data) {
//             var href = window.location.href;
//             var return_href = href.split('/')[0];
//             window.location.href = return_href;
//         }
//     })
// });


//сброс пароля
// !!!!!!!!!!!!!!!!!!!!!надо сделать!!!!!!!!!!!!!!
// function formRegistration(form) {
//     form.validate({
//         submitHandler: function(form) {
//             var csrf_token = $('meta[name="csrf-token"]').attr('content');
//             var formdata = $(form).serialize();
//             console.log(formdata);
//             $(form)[0].reset();
//             $.ajax({
//                 url: routes.postRegister,
//                 type: 'POST',
//                 data: {
//                     "_token" : csrf_token,
//                     "data": formdata,
//                 },
//                 success: function(data) {
//
//                 }
//             })
//         }
//     });
// };
// $(".do_sign_in").click(function(e) {
//     e.stopPropagation();
//     e.preventDefault();
//     var formVal = $(this).closest('.sign_in_form');
//     formRegistration(formVal);
//     formVal.submit();
// });
// --------------------- Форма отзывов -----------------------------
var Main = (function ($) {
    var labelText = $('.form__file-lt:lt(1)').text();
    $(function () {
        Main.init();
    })
    return {
        init: function () {
            var self = this;
            self.inputHadler();
            self.valid();
            self.formValidates();
            self.fileInputs();
        },
        fileInputs: function () {
          $('.js_add_file').click(function(e) {
              e.preventDefault();
              var nb
              if($('.js_item_file_reviews').length == 0) {
                nb = $('.js_item_file_reviews').length + 1;
              } else {
                nb = + $('.js_item_file_reviews').attr('data-number') + 1;
              }
              if($('.js_item_file_reviews').length < 2) {
                console.log(nb);
                var sizeError = document.querySelector('.form__size-error-js'),
                    extensionError = document.querySelector('.form__extention-err-js');
                var whiteList = [
                    "text/plain",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.oasis.opendocument.text",
                    "application/rtf",
                    "application/pdf",
                    "application/octet-stream",
                    "image/jpeg",
                    "image/png",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.oasis.opendocument.spreadsheet",
                ];
                var $input = $('  <input type="file" value="" name="photo_' + nb + '" accept="image/*" class="input-hidden input-file-js"/>');
                $input.on('change', function(evt) {
                    if (this.files.length) {
                        for (var i = 0; i < this.files.length; i++) {
                            if ((whiteList.indexOf(this.files[i].type) != -1 && this.files[i].size < 12000000) || ((this.files[i].name).indexOf('.dwg') != -1 && this.files[i].size < 12000000)) {
                                sizeError.classList.remove('js_show');
                                extensionError.classList.remove('js_show');
                                var f = evt.target.files[0];
                                $('#review_form').append($(this));
                                $('ul.js_list_file_reviews').append('<li class="js_item_file_reviews item_file_reviews" data-number="' + nb + '" data-item="photo_' + nb + '"><span class="name">' + f.name + '</span><span class="js_item_file_delete item_file_delete"></span></li>');
                                if($('.js_item_file_reviews').length == 2) {
                                    document.querySelector('.js_add_file').classList.add('js_hide');
                                }
                            } else {
                                sizeError.classList.remove('js_show');
                                extensionError.classList.remove('js_show');
                                if (whiteList.indexOf(this.files[i].type) == -1) extensionError.classList.add('js_show');
                                if (this.files[i].size > 12000000) sizeError.classList.add('js_show');
                                console.log(this.files[i].size);
                                console.log(whiteList.indexOf(this.files[i].type));
                                $(this).value = '';
                                $(this).closest('.form__file-wr').find('.form__file-remove').addClass('js_hide');
                            }
                        }
                    } else {
                        // filename.setAttribute('title', '');
                        // filename.innerHTML = labelText;
                    }
                });
                $input.hide();
                $input.trigger('click');
              } else {
                $(this).classList.add('js_hide');
              }
          });
        },
        valid: function (data) {
            function sendTextFields(item, formdata, file = '') {
                var dataForm;
                if (file != '') {
                    dataForm = {
                        data: formdata,
                        file: file,
                        subj: $('#review_form').attr('data-subj'),
                    }
                } else {
                    dataForm = {
                        data: formdata,
                        subj: $('#review_form').attr('data-subj'),
                    };
                };
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: dataForm,
                    success: function (data) {
                        $(item)[0].reset();
                        if (document.querySelectorAll('.input-file-js') != null) {
                            $('.js_add_file').removeClass('js_hide');
                            $('.js_list_file_reviews').empty();
                            $('.input-file-js').remove();
                        };
                        setTimeout(function () {
                            $(".review-form-js").find('.forms__success').slideDown(300);
                            $('.input_input').next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
                            $('.input_input').removeClass('active_input');
                        }, 100);
                        setTimeout(function () {
                            $(".review-form-js").find('.forms__success').slideUp(300);
                        }, 3000);
                    }
                })
            };
            var item = data;
            $(item).validate({
                errorElement: 'span',
                errorClass: 'span__error',
                ignoreTitle: true,
                errorPlacement: function(error, element) {
                    error.appendTo($(element).parents('.input_block'));
                },
                highlight: function(element) {
                    $(element).parents('.input_wrapper').addClass("forms__error");
                },
                unhighlight: function(element) {
                    $(element).parents('.input_wrapper').removeClass("forms__error");
                },
                submitHandler: function (form) {
                    var csrf_token = $('meta[name="csrf-token"]').attr('content');
                    var formdata = $(item).serialize();
                    if (document.querySelectorAll('.input-file-js') != null && document.querySelectorAll('.input-file-js').length != 0) {
                        var inputs = $('.input-file-js');
                        let $data = {};
                        for (let i = 0; i < inputs.length; i++) {
                            let element = inputs[i];
                            let next_el = (inputs[i + 1]) ? (inputs[i + 1]) : null;
                            if (element != null && element.files.length) {
                                let reader = new FileReader();
                                reader.onload = function () {
                                    let key = 'file_' + i;
                                    let value = reader.result;
                                    $data[key] = value;
                                    if (next_el == null || !next_el.files.length) {
                                        setTimeout(function () {
                                            $.ajax({
                                                type: 'POST',
                                                url: routes.postLoadFile,
                                                data: {
                                                    "_token": csrf_token,
                                                    "data": $data,
                                                },
                                                success: function (response) {
                                                    var uploadedFile = response.file_name;
                                                    sendTextFields(item, formdata, uploadedFile);
                                                },
                                                error: function (response) {},
                                            });
                                        }, 100);
                                    }
                                };
                                reader.readAsDataURL(inputs[i].files[0]);
                            }
                        }
                    } else {
                        sendTextFields(item, formdata);
                    }
                }
            });
        },
        formValidates: function () {
            var buttons = document.getElementsByClassName('do_reviews_form'),
            form = document.getElementsByClassName('review-form-js');

            function submitForm(event) {
                event.preventDefault();
                event.stopPropagation();

                Main.valid(form);
                $(form).submit();

                return form;
            };
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', submitForm, true);
            };

        },
        inputHadler: function () {
            $(document).on('click', '.js_item_file_delete', function(e){
                var item_file = $(this).parent('.js_item_file_reviews').attr('data-item');
                $(this).parent('.js_item_file_reviews').remove();
                $('.input-file-js[name='+item_file+']').remove();
                if($('.js_item_file_reviews').length < 2) {
                    $('.js_add_file').removeClass('js_hide');
                }
            });
        }
    }
})(jQuery);
// --------------------- Форма отзывов -----------------------------
