import Inputmask from "inputmask";

$(document).ready(function(){
    $('input[type=tel]').each(function() {
        $(this).keypress(function(e, a) {
            if ((e.which == 13) || (e.keyCode == 13)) {
                $(this).blur();
            }
            return true;
        });
    });

    Inputmask({
        mask: '+38 (999) 999-99-99',
        clearMaskOnLostFocus: true,
        clearIncomplete: true
    }).mask('input[type=tel]');
});
