function openChars() {
    if(!$('.js_open_chars').hasClass('js_active')) {
        $('.js_hide_chars').removeClass('js_active');
        $('.js_open_chars').addClass('js_active');
    }
    if($('.good_chars_item').hasClass('hide_js')) {
        $('.good_chars_item').removeClass('hide_js');
    }
}
function closeChars () {
    if(!$('.js_hide_chars').hasClass('js_active')) {
        $('.js_open_chars').removeClass('js_active');
        $('.js_hide_chars').addClass('js_active');
        var charsCompare = document.querySelector('.compare-container.js_active');
        var charsFilter = document.querySelector('.bottom-compare-filter.js_active');
        let vals_array = {};
        if (charsCompare) {
            var childCharsVal  = charsCompare.querySelectorAll('.js_chars-compare');
            var compareItem = charsCompare.querySelectorAll('.compare-good-item ');
        }
        if (charsFilter) {
            var childCharsName = charsFilter.querySelectorAll('.good_chars_item');
        }
        if (childCharsVal) {
            for (var i = 0; i < childCharsVal.length; i++) {
                let key = childCharsVal[i].getAttribute('data-val-id');
                if (vals_array[key]) {
                    vals_array[key] = vals_array[key] +1;
                } else {
                    vals_array[key] = 1;
                }
            }
            for (var item in vals_array) {
                if (vals_array[item] == compareItem.length){
                    for (var i = 0; i < childCharsVal.length; i++) {
                        if( childCharsVal[i].getAttribute('data-val-id') == item) {
                            childCharsVal[i].classList.add('hide_js');
                            var col = childCharsVal[i].getAttribute('data-size-col');
                            for (var j=0; j < childCharsName.length; j++) {
                                if (childCharsName[j].getAttribute('data-size-col') == col) {
                                    if (!childCharsName[j].classList.contains('hide_js')) {
                                        childCharsName[j].classList.add('hide_js');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

window.onload = function () {
    var activeCompare = localStorage.getItem('active_compare');
    if(activeCompare != null) {
        if(activeCompare == 1) {
            openChars();
        } else if (activeCompare == 2) {
            closeChars();
        }
    } else {
        openChars();
    }
}

$(document).ready(function() {
    setTimeout(function() {
        $('.js_compare-item-top').equivalent();
    },300)

    $('.compare-list').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite:false,
        arrows:true,
        prevArrow: '<div class="slick-arrow slick-prev"></div>',
        nextArrow: '<div class="slick-arrow slick-next"></div>',
        responsive:[
            {
                breakpoint : 1300,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint : 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint : 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });


    var charsItem = document.querySelectorAll('.good_chars_item');
    for (var i=0; i< charsItem.length; i++) {
        $('[data-size-col = '+ i +']').equivalent();
    }
    // Стилизация селекта !!!!!!!!!!!!!!!!!!!!!!!!
    $(function() {
        $('.select').selectric({
            disableOnMobile: false,
            nativeOnMobile: false
        });
    });

    if (localStorage.getItem('selectActive') != undefined) {
        var selectVal = localStorage.getItem('selectActive')
    }
    else {
        var selectVal = $('.select').val();
    }

    $('[data-tab-item=tab_'+ selectVal +']').addClass('js_active');
    $('[data-filter-item=tab_'+ selectVal +']').addClass('js_active');

    localStorage.setItem('selectActive', selectVal);

    var activeSelect = localStorage.getItem('selectActive');
    if ($('[data-tab-item=tab_'+ activeSelect +']').find('.compare-good-item').length) {
        activeSelect = localStorage.getItem('selectActive');
    } else {
        activeSelect = $('.select').val();
    }

    if (activeSelect != null) {
        $('select').val(activeSelect).selectric('refresh');
        $('.compare-container').removeClass('js_active');
        $('.bottom-compare-filter').removeClass('js_active');
        $('[data-tab-item=tab_'+ activeSelect +']').addClass('js_active');
        $('[data-filter-item=tab_'+ activeSelect +']').addClass('js_active');
    }


    $('.select').selectric().on('change', function() {
        selectVal = $(this).val();
        // compareSlick();
        localStorage.setItem('selectActive', selectVal);

        $('.compare-container').removeClass('js_active');
        $('.bottom-compare-filter').removeClass('js_active');
        $('[data-tab-item=tab_'+ selectVal +']').addClass('js_active');
        $('[data-filter-item=tab_'+ selectVal +']').addClass('js_active');

        $('.js_hide_chars').removeClass('js_active');
        $('.js_open_chars').addClass('js_active');
        openChars();
    });
    $('.js_hide_chars').click(function () {
        closeChars ();
        var attrCompare = $(this).attr('data-compare');
        localStorage.setItem('active_compare', attrCompare);
    });

    $('.js_open_chars').click(function () {
        openChars();
        var attrCompare = $(this).attr('data-compare');
        localStorage.setItem('active_compare', attrCompare);
    });

});


$.fn.equivalent = function (){
    $(this).removeAttr('style');
    var $blocks = $(this),
        maxItem    = $blocks.eq(0).outerHeight();
    $blocks.each(function(){
        maxItem = ( $(this).outerHeight() > maxItem ) ? $(this).outerHeight() : maxItem;
    });
    $blocks.outerHeight(maxItem);
    if ($blocks.hasClass('js_compare-filter') && $( window ).width() < 576) {
        $('.js_compare-filter').removeAttr('style');
    }
}

$(window).on('load resize', function () {
    $('.js_compare-item-top').equivalent();

    if ($(window).width() < 576) {
        var heightAdaptive = $('.js_compare-filter').outerHeight() + $('.js_compare_item_adaptive').outerHeight();
        heightAdaptive  = heightAdaptive + 175;
        $('.js_bottom-compare-filter').css('top',heightAdaptive);
    } else {
        $('.js_bottom-compare-filter').css('top',0)
    }

});
